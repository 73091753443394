import React from 'react';
import { Spinner } from 'react-bootstrap';

import { BasicButtonProps } from '../lib/types';

const BasicButton = ({
  children,
  disabled,
  loading,
  type,
  onClick,
  testId,
  className,
}: BasicButtonProps): JSX.Element => (
  <button
    data-testid={`basic-button-${testId}`}
    disabled={disabled || loading}
    className={`ml-auto m-0 h-16 wd-btn ${className}`}
    type={type}
    onClick={onClick}>
    {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
    {!loading && children?.toLocaleUpperCase()}
  </button>
);

export default BasicButton;
