import React from 'react';

import t from '../lib/translations';
import { SelectProps } from '../lib/types';

export default function Select({
  name,
  label,
  children,
  errors,
  inputRef,
  onChange,
  disabled = false,
  defaultValue,
  value,
  className,
  containerClassName,
  labelClassName,
}: SelectProps): JSX.Element {
  const error = errors?.[name];

  return (
    <div className={`md:flex md:items-center ${containerClassName}`}>
      {label && (
        <label
          htmlFor={name}
          className={`block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-2 ${labelClassName}`}>
          {label}
        </label>
      )}

      <div className={`relative ${className}`}>
        <select
          data-testid={name}
          id={name}
          name={name}
          value={value}
          ref={inputRef}
          disabled={disabled}
          onChange={onChange}
          defaultValue={defaultValue}
          className={`block appearance-none w-full bg-gray-200 border-2 border-gray-200 text-gray-700 
                py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500
                ${disabled && 'bg-gray-400'}
              `}>
          {children}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
      {error && <p className="text-red-500 text-xs italic mt-1">{t.error[error?.message]}</p>}
    </div>
  );
}
